import React, { useCallback, useEffect, useRef } from "react";

import { useChat } from "hooks";
import { Loader } from "./loader/loader";
import { Message } from "./message/message";

import styles from "./conversation.module.scss";

export const Conversation: React.FC = () => {
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const { messages } = useChat();

  useEffect(() => {
    messagesContainerRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);

  const showResponderAvatar = useCallback(
    (index: number) => {
      const indexes: number[] = [];
      messages?.forEach((message, index) => {
        if (message.sendBy === "client") {
          indexes.push(index - 1);
        }
      });

      const lastMessageBeforeResponse = indexes.includes(index);
      const lastMessageSent = index === messages.length - 1;

      return lastMessageBeforeResponse || lastMessageSent;
    },
    [messages],
  );

  return (
    <div className={styles.container} ref={messagesContainerRef}>
      {messages.map((message, index) => (
        <Message key={index} message={message} showResponderAvatar={showResponderAvatar(index)} />
      ))}
      <Loader />
    </div>
  );
};
