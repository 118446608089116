import React from "react";

import logo from "assets/logo.png";

import styles from "./footer.module.scss";

export const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <img src={logo} alt="Insurance wellness hub" className={styles.logo} />

      <p className={styles.text}>
        This site is fully owned and operated by MyHealthAngel Insurance LLC
        <br />
        <br />
        Trademarks utilized belong to their respective owners and no implied or expressed endorsement of our website or
        services is intended. Through in-depth research and experienced editors we provide feedback about products and
        services. We are independently owned, and opinions expressed here are our own.
        <br />
        <br />
        THIS IS AN ADVERTORIAL AND NOT AN ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE. Our disclaimer is
        that this site does receive compensation for product reviews and referrals or purchases made through our links.
        The story depicted here is for demonstration purposes only and everyone{"'"}s results may vary. We hope you find our
        online resource informative and helpful. This website and the company that owns it is not responsible for any
        typographical or photographic errors. If you do not agree to our terms and policies, then please leave this site
        immediately. All trademarks, logos, and service marks (collectively the &quot;Trademarks&quot;) displayed are registered
        and/or unregistered Trademarks of their respective owners.
        <br />
        <br />
        This website is not affiliated with the Affordable Cares Act program or any other government entity. The
        information provided on this website is for informational purposes only. It is not intended to be, nor does it
        constitute any kind of financial advice. Please seek advice from a qualified professional prior to making any
        financial decisions based on the information provided. PLEASE BE AWARE THAT THIS IS AN ADVERTISEMENT AND NOT AN
        ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE. For Americans not on Medicaid or Medicare
      </p>

      <div className={styles.linksWrapper}>
        <a
          href="https://www.seniorangel.com/privacy-statement/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkText}
        >
          Privacy Policy
        </a>{" "}
        |
        <a
          href="https://www.seniorangel.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkText}
        >
          Terms of use
        </a>{" "}
      </div>
    </div>
  );
};
