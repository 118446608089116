export type Ending = {
  firstParagraph: string;
  secondParagraph: string;
  thirdParagraph: string;
  fourthParagraph?: string;
  component: { text: string; href: string; className?: string };
};

export const acaSuccess: Ending = {
  firstParagraph: "🎉 Congratulations! 🎁",
  secondParagraph: "You’re pre-approved for a $6395 health subsidy.",
  thirdParagraph:
    "You can use savings at stores across the nation (like Walmart and other major chains). Use for groceries, rent, medical expenses, and so on. Hold on while I finalize things..",
  fourthParagraph:
    "Good News! I have an agent available to claim your subsidy. Tap the number button below to call now--it only takes 2 minutes.",
  component: {
    text: "(800) 967-1963",
    href: "tel:+18009671963",
    className: "aca_call",
  },
};

export const medicareSuccess: Ending = {
  firstParagraph: "🎉 Congratulations! 🎁",
  secondParagraph: "You’re pre-qualified for Advanced Premium Insurance Benefit.",
  thirdParagraph: "Tap the number button below to call now and get health subsidy. It only takes 2 minutes.",
  component: {
    text: "(800) 967-3628",
    href: "tel:+18009673628",
    className: "medicare_call",
  },
};

export const medicaidSuccess: Ending = {
  firstParagraph: "🎉 Congratulations! 🎁",
  secondParagraph: "You’re pre-qualified for Advanced Premium Insurance Benefit.",
  thirdParagraph: "Tap the number button below to call now and get health subsidy. It only takes 2 minutes.",
  component: {
    text: "(800) 967-9233",
    href: "tel:+18009679233",
    className: "medicaid_call",
  },
};
