import { useContext, useEffect, useRef } from "react";

import { ChatContext } from "context";
import { Message } from "types";
import { AddMessageOptions } from "hooks/use-chat/use-chat.types";

export const useChat = () => {
  const timers = useRef<Record<string, NodeJS.Timer>>({});

  const { messages, setMessages, typing, setTyping } = useContext(ChatContext);

  useEffect(() => {
    const timerKeys = Object.keys(timers.current);

    return () => {
      timerKeys.forEach((key) => {
        clearTimeout(key);
      });
    };
  }, []);

  const addResponderMessage = (content: Message["content"], options: AddMessageOptions) => {
    const key = "id" + Math.random().toString(16).slice(2);

    timers.current[key] = setTimeout(() => {
      const message: Message = { content, sendBy: "responder" };
      setMessages((prevMessages) => [...prevMessages, message]);
      options?.callback?.();
    }, options.delay);
  };

  const addClientMessage = (content: Message["content"], options: AddMessageOptions) => {
    const key = new Date().getTime().toString();

    timers.current[key] = setTimeout(() => {
      const message: Message = { content, sendBy: "client" };
      setMessages((prevMessages) => [...prevMessages, message]);
    }, options.delay);
  };

  const deleteComponentMessage = () => {
    setMessages((prevState) => prevState.slice(0, -1));
  };

  const showTypingEffect = () => {
    setTyping(true);
  };

  const removeTypingEffect = () => {
    setTyping(false);
  };

  return {
    messages,
    typing,
    addResponderMessage,
    addClientMessage,
    deleteComponentMessage,
    showTypingEffect,
    removeTypingEffect,
  };
};
