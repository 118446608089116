import React from "react";

import { useChat } from "hooks";

import styles from "./loader.module.scss";

export const Loader: React.FC = () => {
  const { typing } = useChat();

  if (!typing) return null;

  return (
    <div className={styles.loader}>
      <div className={styles.loaderContainer}>
        <span className={styles.loaderDot} />
        <span className={styles.loaderDot} />
        <span className={styles.loaderDot} />
      </div>
    </div>
  );
};
