import React from "react";

import { Message as MessageType } from "types";

import user from "assets/user.png";
import responder from "assets/responder.jpg";

import styles from "./message.module.scss";

interface Props {
  message: MessageType;
  showResponderAvatar: boolean;
}

export const Message: React.FC<Props> = ({ message, showResponderAvatar }) => {
  const client = message.sendBy === "client";

  return (
    <div className={`${styles.container} ${client ? styles.clientContainer : ""}`}>
      {!client && (
        <>
          {showResponderAvatar ? (
            <img src={responder} className={styles.avatar} alt="profile" />
          ) : (
            <div style={{ width: "44px" }} />
          )}
        </>
      )}
      <div className={client ? styles.clientMessage : styles.responderMessage}>
        {typeof message.content === "string" && <span>{message.content}</span>}
        {typeof message.content !== "string" && message.content}
      </div>

      {client && <img src={user} className={styles.avatarClient} alt="profile" />}
    </div>
  );
};
