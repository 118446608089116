import React, { useEffect } from "react";

import { Chat } from "./components/chat/chat";
import { ChatContextProvider } from "context";

import styles from "./app.module.scss";

export function App() {
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted || performance.getEntriesByType("navigation")[0]?.entryType === "back_forward") {
        window.location.reload();
      }
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <ChatContextProvider>
        <Chat />
      </ChatContextProvider>
    </div>
  );
}
