import React, { useState } from "react";

type Message = {
  content: string | React.ReactNode;
  sendBy: "responder" | "client";
};

type ChatContextType = {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  typing: boolean;
  setTyping: (typing: boolean) => void;
};

const chatInitialValues: ChatContextType = {
  messages: [],
  setMessages: () => null,
  typing: false,
  setTyping: () => null,
};

export const ChatContext = React.createContext<ChatContextType>(chatInitialValues);

interface Props {
  children: React.ReactNode;
}

export const ChatContextProvider: React.FC<Props> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [typing, setTyping] = useState<boolean>(false);

  return <ChatContext.Provider value={{ messages, setMessages, typing, setTyping }}>{children}</ChatContext.Provider>;
};
