import React from "react";

import styles from "./header.module.scss";

export const Header: React.FC = () => {
  return (
    <div className={styles.headerWrapper}>
      <h2 className={styles.title}>
        Hurry, 24 Hours Left to Get Your $6,400 Government Approved Health Subsidy. Chat Below to Get Yours For Free
        Today.
      </h2>
      <div className={styles.chatOnline}>
        <div className={styles.chatPulseCircle}></div>
        <span>Emily is Online.</span>
      </div>
    </div>
  );
};
