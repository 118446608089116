import React, { useEffect } from "react";

import { Header } from "./header/header";
import { Conversation } from "./conversation/conversation";
import { Footer } from "./footer/footer";
import { useChat } from "hooks";
import { acaSuccess, Ending, medicaidSuccess, medicareSuccess } from "./chat.constants";

import styles from "./chat.module.scss";

export const Chat: React.FC = () => {
  const { addResponderMessage, addClientMessage, deleteComponentMessage, showTypingEffect, removeTypingEffect } =
    useChat();

  useEffect(() => {
    showTypingEffect();
    addResponderMessage("Hi 👋", { delay: 1000 });
    addResponderMessage("I’m Emily from the Enrollment Center.", { delay: 2500 });
    addResponderMessage("Do you want to see if you qualify to get your $6,400 Government Health Subsidy? Tap Yes! 😃", {
      delay: 4500,
    });
    addResponderMessage(
      <div className={styles.optionsContainer}>
        <button className={styles.chatButton} onClick={display60kYearQuestion}>
          Yes
        </button>
      </div>,
      {
        delay: 4500,
        callback: removeTypingEffect,
      },
    );
  }, []);

  const display60kYearQuestion = async () => {
    showTypingEffect();
    deleteComponentMessage();
    addClientMessage("Yes", { delay: 0 });

    addResponderMessage("Do you make less than $60k a year?", { delay: 2000 });
    addResponderMessage(
      <div className={styles.optionsContainer}>
        <button
          className={styles.chatButton}
          onClick={() => {
            displayProgramQuestion("Yes");
          }}
        >
          Yes
        </button>
        <button
          className={styles.chatButton}
          onClick={() => {
            displayProgramQuestion("No");
          }}
        >
          No
        </button>
      </div>,
      { delay: 3500, callback: removeTypingEffect },
    );
  };

  const displayProgramQuestion = (answeredText: string) => {
    deleteComponentMessage();
    addClientMessage(answeredText, { delay: 0 });
    showTypingEffect();

    addResponderMessage("Are you currently on these government programs?", { delay: 2000 });
    addResponderMessage(
      <div className={styles.optionsColumn}>
        <button
          className={styles.chatButton}
          onClick={() => {
            displayEnding("No", acaSuccess);
          }}
        >
          No
        </button>
        <button className={styles.chatButton} onClick={() => displayEnding("Medicare", medicareSuccess)}>
          Medicare
        </button>
        <button className={styles.chatButton} onClick={() => displayEnding("Medicaid", medicaidSuccess)}>
          Medicaid
        </button>
      </div>,
      { delay: 2000, callback: removeTypingEffect },
    );
  };

  const displayEnding = (answeredText: string, ending: Ending) => {
    deleteComponentMessage();
    addClientMessage(answeredText, { delay: 0 });
    showTypingEffect();

    addResponderMessage(ending.firstParagraph, { delay: 1000 });
    addResponderMessage(ending.secondParagraph, { delay: 2000 });
    addResponderMessage(ending.thirdParagraph, { delay: 3000 });
    if (ending.fourthParagraph) addResponderMessage(ending.fourthParagraph, { delay: 4000 });
    addResponderMessage(
      <a className={`${styles.chatButton} ${ending.component.className}`} href={ending.component.href}>
        {ending.component.text}
      </a>,
      { delay: 4500, callback: removeTypingEffect },
    );
  };

  return (
    <>
      <div className={styles.chatWrapper}>
        <Header />
        <Conversation />
      </div>
      <Footer />
    </>
  );
};
